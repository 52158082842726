import React, { Fragment, ReactNode, useEffect } from 'react';
import * as braze from '@braze/web-sdk';
import { useSelector } from 'react-redux';
import { getUserExternalId } from 'store/selectors/auth';

const BrazeProvider = ({ children }: { children: ReactNode }) => {
  const externalId = useSelector(getUserExternalId);

  useEffect(() => {
    const apiKey = window.env.BRAZE_API_KEY;
    const endpoint = window.env.BRAZE_ENDPOINT;

    if (!apiKey || !endpoint) {
      console.warn('BRAZE NOT INITIALIZED!');
      return;
    }

    braze.initialize(apiKey, {
      baseUrl: endpoint,
      allowUserSuppliedJavascript: true,
    });

    braze.automaticallyShowInAppMessages();

    return () => {
      braze.destroy();
    };
  }, []);

  useEffect(() => {
    const currentUserId = braze.getUser()?.getUserId();

    if (externalId && externalId !== currentUserId) {
      braze.changeUser(externalId);
      braze.openSession();
    }
  }, [externalId]);

  return <Fragment>{children}</Fragment>;
};

export default BrazeProvider;
